<template>
  <section class="content">
    <table class="table table-hover" ref="tblijin" id="example">
      <thead>
        <tr>
          <th>TANGGAL</th>
          <th>STAFF</th>
          <th>JAM MASUK</th>
          <th>JAM KELUAR</th>
          <th>JENIS</th>
          <th>KETERANGAN</th>
          <th>STATUS</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title"></h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                <b>Please correct the following error(s):</b>
                <ul>
                  <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                </ul>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </p>
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">TANGGAL</label>
                  <datepicker v-model="form.tanggal" id="tanggal" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">WAKTU</label>
                  <div class="row">
                    <timepicker
                      class="col-md-5 ml-2"
                      placeholder="Jam Masuk"
                      v-model="form.jam_mulai"
                    />
                    <timepicker
                      class="col-md-5 ml-2"
                      placeholder="Jam Keluar"
                      v-model="form.jam_selesai"
                    />
                  </div>
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">STAFF</label>
                    <v-select :options="optStaff" v-model="form.staff_id" :reduce="opt => opt.id" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">JENIS IJIN</label>
                  <v-select :options="jenis_ijin" :reduce="opt => opt.jenis" :value="form.tipe_ijin" v-model="form.tipe_ijin"></v-select>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">KETERANGAN</label>
                  <textarea
                    class="form-control"
                    id="keterangan"
                    rows="3"
                    v-model="form.keterangan"
                    placeholder="Tuliskan keterangan"
                  ></textarea>
                </div>
                <div class="col-md-6 form-group">
                    <label for="inputFile">FILE UPLOAD</label>
                    <div class="custom-file">
                        <input type="file" class="custom-file-input inputfile" id="inputFile"  name="file" @change="uploadFoto(this)">
                        <label class="custom-file-label" for="inputFile">Silahkan upload</label>
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" @click="submitType='draft'" id="draft" class="btn btn-default border-dark mr-2">
                Draft
              </button>
              <button type="submit" @click="submitType='submit'" id="submit" class="btn btn-primary mr-2">
                Simpan
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import datepicker from "@/components/Datepicker";
import timepicker from "@/components/Timepicker";
import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";

export default {
  name: "",
  data() {
    return {
      errors: [],
      optStaff: [],
      method: "",
      roles: "",
      formTitle: "Tambah",
      jenis_ijin: [
                {label: 'Cuti', jenis: 'cuti'}, 
                {label: 'Ijin', jenis: 'ijin'},
      ],
      form: {
        staff_id: "",
        tanggal: "",
        jam_mulai: "",
        jam_selesai: "",
        tipe_ijin: "",
        keterangan: "",
        img_code: "",
        status: "",
        submit: "",
      },
      form_data: "",
      keterangan: "",
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    authFetch('/management/log_buku/staff')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
            this.optStaff = js.data;
    });
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;

      this.doUpload()
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }

          return res.json();
        })
        .then((js) => {
          console.log("result");
          console.log(js);
          this.errors = [];
          if (!js.status) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          } else {
            var self = this;
            const e = this.$refs;
            self.form.img_code = js.message[0].id;

            if(ev.submitter){
                self.form.submit = ev.submitter.id;
            }
            else{
                self.form.submit = this.submitType
            }

            var data = Object.keys(this.form)
              .map(
                (key) =>
                  encodeURIComponent(key) +
                  "=" +
                  encodeURIComponent(this.form[key])
              )
              .join("&");
            var urlSubmit = "/management/pengajuan_ijin/allstaff";
            if (this.method == "PUT")
              urlSubmit = "/management/pengajuan_ijin/allstaff/" + this.form.id;

            authFetch(urlSubmit, {
              method: this.method,
              body: data,
            })
              .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
              })
              .then((js) => {
                this.errors = [];
                if (!js.success) {
                  console.log(js.details);

                  for (var key in js.details) {
                    if (js.details.hasOwnProperty(key)) {
                      this.errors.push(js.details[key]);
                    }
                  }

                  return;
                }
                this.table.api().ajax.reload();
                $(e.formDialog).modal("hide");
              });
          }
        });

      ev.preventDefault();
    },
    uploadFoto: function (file) {
      var file_data = $("#inputFile").prop("files");
      var form_data = new FormData();
      var fileInput = $("#inputFile")[0];
      $.each(fileInput.files, function (k, file) {
        form_data.append("file", file);
      });
      form_data.append("subdir", "/pengajuan-ijin/");
      form_data.append("id", this.form.id);
      this.form_data = form_data;
      return false;
    },
    doUpload: function () {
      return authFetch("/management/pengajuan_ijin/upload_data", {
        method: "POST",
        body: this.form_data,
        headers: {
          "Content-Type": null,
        },
      });
    },
  },
  components: {
    datepicker,
    timepicker,
    vSelect,
    datePicker,
  },
  mounted() {
    const e = this.$refs;
    let self = this;

    this.table = createTable(e.tblijin, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/management/pengajuan_ijin/allstaff",
      serverSide: true,
      columns: [
        { data: "tanggal", width: "20px" },
        { data: "staff" },
        { data: "jam_mulai", width: "70px" },
        { data: "jam_selesai", width: "70px" },
        { data: "tipe_ijin", width: "60px",
        render: function (data, type, row, meta) {
          if(data < 2){
            return '<span class="badge badge-primary">CUTI</span>';
          } else {
            return '<span class="badge badge-primary">IJIN</span>';
          }
        } },
        { data: "keterangan" },
        { data: "status", width: "50px",
          render: function (data, type, row, meta) {
            return data == 0 ? '<span class="badge badge-secondary">DRAFT</span>' : data == 1 ? '<span class="badge badge-info">REQUEST</span>' : data == 2 ? '<span class="badge badge-success">APPROVED</span>' : data == 3 ? '<span class="badge badge-danger">REJECT</span>' : '';
          } },
      ],
      filter: false,
      rowCallback: function (row, data) {},
      buttonClick: (evt) => {
        if (evt.role == "create") {
            self.form= {};
            self.form_data = "";
            self.method = "POST";
            self.errors = [];
            $("#draft").show();
            $("#submit").show();
            self.formTitle = "Tambah Form";
            $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
            self.form = evt.data;
            if(self.form.status > 0){
            $("#draft").hide();
            $("#submit").hide();
            } else {
            $("#draft").show();
            $("#submit").show();
            }
            self.method = "PUT";
            self.errors = [];
            self.formTitle = "Edit Form";
            $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
            console.log(evt);
            Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/management/pengajuan_ijin/allstaff/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
    });
  },
};
</script>
<style scoped>
/* td.details-control {
  font-weight: 900; content: "\f055";
  cursor: pointer;
}

tr.shown td.details-control {
  font-weight: 900; content: "\f056";
  cursor: pointer;
} */
</style>
