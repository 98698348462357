<template>
    <input class="form-control" :value="value" />
</template>
<script>
import $ from 'jquery';
//import "bootstrap-datepicker/dist/css/boostrap-datepicker.css";
import '@/libs/timepicker/js/timepicker';
import '@/libs/timepicker/css/timepicker.css';

export default {
    name: 'Timepicker',
    props: ['value', 'options'],
    mounted: function () {

        var self = this;
        var params = {};
        if(this.options)params = this.options;
        //{format: 'dd/mm/yyyy', autoclose:true,startView: "months", minViewMode: "months"}
        var el = $(self.$el)                    
            .timepicker(params) // init datepicker
            console.log('time', el)
        el.update(function(e){
            self.$emit('input', self.$el.value);
        })
            /*.trigger('change')                    
            .on('change', function () { // emit event on change.
                self.$emit('input', this.value);
            })*/
    },
    beforeDestroy: function() {
        //$(this.$el).datepicker('hide').datepicker('destroy');
    },
    watch: {
        value: function(old, val) {
            $(this.$el).val(val);
        }
    }
}
</script>
<style scoped>
</style>